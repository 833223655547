<template>
  <div class="container">
    <div class="container content">
      <!--  <v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <div class="box-container title-box">
        <!--<img class="org-image" src="../assets/images/circle-placeholder.png">-->
        <div class="organization-icon-box">
          <div class="organization-icon"></div>
        </div>
        <div class="title-page">
          <h1 class="name">{{ organization.name }}</h1>
          <div>
            {{ formattedAddress }}
            <span
              class="mr-2 ml-2"
              v-if="organization.website && formattedAddress"
              >&#8226;</span
            ><a
              v-if="organization.website"
              :href="formattedWebsite"
              target="_blank"
              ><strong>{{ organization.website }}</strong></a
            >
          </div>
          <v-chip
            class="ma-2 font-weight-bold white--text"
            color="var(--green)"
            v-if="organization.is_partner"
          >
            Partner
          </v-chip>
          <v-chip class="ma-2 tag-chip" v-if="organization.type">{{
            organization.type[1]
          }}</v-chip>
        </div>
      </div>
      <div v-if="organization.is_partner">{{ organization.description }}</div>
      <button
        class="tablink tablinkhalf"
        v-on:click="setTab('engagements')"
        v-bind:class="{ active: this.activeTab === 'engagements' }"
      >
        Engagements
      </button>
      <!-- <button
        class="tablink tablinkthird"
        v-on:click="setTab('contacts')"
        v-bind:class="{ active: this.activeTab === 'contacts' }"
      >
        Contacts
      </button> -->
      <button
        class="tablink tablinkhalf"
        v-on:click="setTab('files')"
        v-bind:class="{ active: this.activeTab === 'files' }"
      >
        Files
      </button>
      <div
        id="overview"
        class="tabcontent"
        v-show="this.activeTab === 'engagements'"
      >
        <div>
          <h2>Direct Engagements</h2>
          <projects-card
            :endPoint="
              `projects?organization=${this.$route.params.id}&highlight=true&`
            "
          ></projects-card>
        </div>
        <timeline
          :filesEndPoint="`attachments?organization=${this.$route.params.id}`"
          :updatesEndPoint="
            `projects/update?organization=${this.$route.params.id}`
          "
          :projectsEndPoint="`projects?organization${this.$route.params.id}`"
        />
        <h2>Associated Program Cohorts</h2>
        <keep-alive>
          <programs-table
            :columns="['Name', 'Center', 'Grant', 'Projects']"
            :centerFilter="false"
            :endPoint="
              `programs?ordering=name&organization=${this.$route.params.id}`
            "
          >
          </programs-table>
        </keep-alive>
        <h2>Associated Projects</h2>
        <keep-alive>
          <projects-card
            :endPoint="`projects?organization=${this.$route.params.id}&`"
            :searchFilter="true"
            :resultsFilter="true"
            :centerFilter="true"
            @projects="projects = $event"
          ></projects-card>
        </keep-alive>
      </div>
      <div
        id="clients"
        class="tabcontent"
        v-show="this.activeTab === 'contacts'"
      >
        <!-- <div>
          <h2 class="pb-8 pt-8">Key Contacts</h2>
          <keep-alive>
            <contacts-table
              :key="refreshTrigger"
              :endPoint="
                `contacts/people?ordering=name&org_key_contact=${this.$route.params.id}`
              "
              :columns="['Name', 'Job Title']"
              :projects="organization.projects"
              :showDeleteContactsButton="true"
            />
          </keep-alive>
        </div>
        <h2 class="pt-8">All Contacts</h2>
        <keep-alive>
          <contacts-table
            @update-key-contacts="refresh"
            :columns="['Name', 'Job Title']"
            :endPoint="
              `contacts/people?ordering=name&organization=${this.$route.params.id}`
            "
            class="mt-8"
            :projects="organization.projects"
            :showAddContactsButton="true"
          ></contacts-table>
        </keep-alive> -->
      </div>
      <div id="files" class="tabcontent" v-show="this.activeTab === 'files'">
        <file-explorer
          :filesEndPoint="`attachments?organization=${this.$route.params.id}`"
          :foldersEndPoint="`folders?organization=${this.$route.params.id}`"
        />
      </div>
    </div>
  </div>
</template>
<style>
/* Page CSS */
.org-image {
  height: 150px;
  max-height: 100%;
}
.name {
  font-family: "roboto-bold";
  margin: 0px;
}
.tablink {
  width: 33%;
}
.organization-icon {
  height: 50px;
  width: 50px;
  margin: 0px auto;
  padding: 55px 10px 10px 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/organizations.svg");
}
.organization-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 0px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
}
.testimonial-button {
  width: 80px;
}
h2 {
  padding-top: 40px;
}
#overview h2:first-child {
  padding-top: initial;
}
</style>

<script>
// import PieChart from "../components/PieChart";
// import PieChart_ProjectStatus from "../components/PieChart_ProjectStatus";
// import Testimonial_Quote from "../components/Testimonial_Quote";
import ProjectsCard from "../components/ProjectsCard";
import ProgramsTable from "../components/ProgramsTable";
import ContactsTable from "../components/ContactsTable.vue";
import FileExplorer from "../components/FileExplorer.vue";
import refreshTriggerMixin from "../mixins/refreshTrigger";
import Timeline from "../components/Timeline.vue";
export default {
  mixins: [refreshTriggerMixin],
  title() {
    return `Organization Detail - GEPI.io`;
  },
  components: {
    ProjectsCard,
    ProgramsTable,
    // ContactsTable,
    FileExplorer,
    Timeline,
  },
  data() {
    return {
      organization: {},
      activeTab: "engagements",
      projects: [],
      contacts: [],
      loaded: false,
    };
  },
  created() {
    this.getOrganization();
  },
  computed: {
    formattedAddress() {
      const {
        street_address,
        city,
        state_region,
        postal_code,
        country_region,
      } = this.organization;

      const addressParts = [
        street_address,
        city,
        state_region,
        postal_code,
        country_region,
      ].filter((part) => part); // Remove falsy values

      return addressParts.join(", ");
    },
    formattedWebsite() {
      const { website } = this.organization;
      return website && !/^https?:\/\//i.test(website)
        ? `http://${website}`
        : website;
    },
    // newProjects: function() {
    //   let newProjectCount = 0;
    //   let currentTime = Math.round(+new Date() / 1000);
    //   let projectTime = 0;
    //   for (var i = 0; i < this.projects.results.length; i++) {
    //     projectTime = Math.floor(
    //       new Date(this.projects.results[i].created_date).getTime() / 1000
    //     );
    //     if (currentTime - projectTime < 604800) {
    //       newProjectCount++;
    //     }
    //   }
    //   return newProjectCount;
    // },
    // newTestimonials: function() {
    //   let newTestimonialCount = 0;
    //   let currentTime = Math.round(+new Date() / 1000);
    //   let testimonialTime = 0;
    //   for (var i = 0; i < this.organization.testimonials.length; i++) {
    //     testimonialTime = Math.floor(
    //       new Date(this.organization.testimonials[i].created_date).getTime() /
    //         1000
    //     );
    //     if (currentTime - testimonialTime < 604800) {
    //       newTestimonialCount++;
    //     }
    //   }
    //   return newTestimonialCount;
    // },
    // projectTypes: function() {
    //array of counted project types. 0 = CRS, 1 = DLV, 2 = PDV
    // let projectTypesBuild = [0, 0, 0];
    // for (var i=0; i < this.projects.results.length; i++){
    //     if(this.projects.results[i].type[0] == "CRS") {
    //         projectTypesBuild[0]++;
    //     }
    //     if(this.projects.results[i].type[0] == "DLV") {
    //         projectTypesBuild[1]++;
    //     }
    //     if(this.projects.results[i].type[0] == "PDV") {
    //         projectTypesBuild[2]++;
    //     }
    // }
    //   return projectTypesBuild;
    // },
    // projectStatus: function() {
    //   let projectStatusBuild = [0, 0, 0];
    //   for (var i = 0; i < this.projects.results.length; i++) {
    //     if (this.projects.results[i].status[0] == "ONT") {
    //       projectStatusBuild[0]++;
    //     }
    //     if (this.projects.results[i].status[0] == "OFT") {
    //       projectStatusBuild[1]++;
    //     }
    //     if (this.projects.results[i].status[0] == "RSK") {
    //       projectStatusBuild[2]++;
    //     }
    //   }
    //   return projectStatusBuild;
    // },
    // projectChartTypes: function() {
    //   const counts = {};
    //   const types = [];
    // this.projects.results.map(p => {
    //     counts[p.type[1]] = (counts[p.type[1]] || 0) + 1;
    //     });
    //   types.push(
    //     ["Type", "Projects"],
    //     ["Course", counts["Course"]],
    //     ["Delivery", counts["Delivery"]],
    //     ["Product", counts["Product"]]
    //   );
    //   return types;
    // },
    // projectChartStatuses: function() {
    //   const counts = {};
    //   const statuses = [];
    //   this.projects.results.map((p) => {
    //     counts[p.status[1]] = (counts[p.status[1]] || 0) + 1;
    //   });
    //   statuses.push(
    //     ["Status", "Projects"],
    //     ["On Track", counts["On Track"]],
    //     ["At Risk", counts["At Risk"]],
    //     ["Off Track", counts["Off Track"]]
    //   );
    //   return statuses;
    // },
  },
  methods: {
    getOrganization: function() {
      this.$api
        .get("contacts/organizations/" + this.$route.params.id + "/")
        .then((response) => {
          this.organization = response.data;
          this.setPageTitle(this.organization.name);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: "404" });
          }
        })
        .finally(() => {
          this.loaded = true; // Reset downloading state to false
        });
    },
    setTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
